@use "./_AppBackgroundColor.module.scss" as *;
@use "./_FontColor.module.scss" as *;
@use "./_ColorsForCustomize.module.scss" as *;
@use "./_BorderRadiuses.module.scss" as *;

$buttons-margin-bottom: 15px;

@mixin buttonGroupBlockContainer {
  width: auto;
  height: 42px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@mixin buttonBackSelf {
  width: 300px;
  height: 100%;
  cursor: pointer;
  background: $back-and-next-buttons-color;
  border-radius: $inner-elements-border-radius;
  margin-right: 10px;
  transition: 0.1s;
  border: none;
  &:hover {
    background: $hover-color;
    transition: 0.1s;
  }
}
@mixin buttonNextSelf {
  width: 300px;
  height: 100%;
  cursor: pointer;
  border: none;
  background: $back-and-next-buttons-color;
  border-radius: $inner-elements-border-radius;
  transition: 0.2s;
  &:hover {
    background: $hover-color;
    transition: 0.2s;
  }
}

@mixin buttons-margin-bottom {
  margin-bottom: $buttons-margin-bottom;
}
