@use "../../../../MAINCSSFORWHOLECOMPONENTS/MainInputsStyles.module" as *;
@use "../../../../MAINCSSFORWHOLECOMPONENTS/IconsForWholeAddressFormPage" as *;
// @use "../../../../../../../../global" as *;
@use "../../../../MAINCSSFORWHOLECOMPONENTS/AppBackgroundColor.module" as *;
@use "../../../../MAINCSSFORWHOLECOMPONENTS/SidePaddings.module" as *;

@use "../../../../MAINCSSFORWHOLECOMPONENTS/_FontColor.module.scss" as *;
@use "../../FleetForm/FleetForm.module.scss" as *;
@use "../../../../MAINCSSFORWHOLECOMPONENTS/_ButtonsGroupBackAndNext.module.scss"
  as *;
@use "../../../../MAINCSSFORWHOLECOMPONENTS/_MainCountersStyles.module.scss" as *;
@use "../../../../MAINCSSFORWHOLECOMPONENTS/_ColorsForCustomize.module.scss" as *;
@use "../../../../MAINCSSFORWHOLECOMPONENTS/_BorderRadiuses.module.scss" as *;

$background-position-x-for-date-time-picker: 3%;

.mainWrapper {
  @include whole-app-background;
  @include side-paddings-for-whole-widget-page;
  width: auto;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  @media (max-width: 867px) {
    height: auto;
  }
  // @media (max-width: 500px) {
  //   padding-top: 75px;
  // }
}
.adressFormWrapperToSetRowDirection {
  height: 50vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  @media (max-width: 867px) {
    flex-direction: column;
    height: 56vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
.mapContainer {
  // @include side-paddings-for-whole-widget-page;

  // z-index: 10;
  // position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 47vh;
  margin-left: 17px;
  margin-top: 19px;
  order: 2;
  @media (max-width: 867px) {
    width: 100%;
    height: 350px;
    order: 1;
  }
  @media (max-width: 541px) {
    box-sizing: border-box;
    height: 260px;
    margin: 0;
    // margin-top: 22px;
  }
  // z-index: 5;
}
.mapContainerMobile {
  width: 100%;
  height: 340px;
  order: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 17px;
  margin-top: 19px;
  @media (max-width: 541px) {
    box-sizing: border-box;
    height: 155px;
    margin: 0;
    // margin-top: 22px;
  }
}
.mapContainerForHideMapsTagsPositioning {
  // position: absolute;

  z-index: 1;
  width: 100%;
  height: 92%;
  padding: 20px;
  @media (max-width: 867px) {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 541px) {
    padding: 0;
  }
  // border-radius: 15px;
}
.mapContainerForBorder {
  width: 100%;
  height: 100%;
  // padding: 20px;
  position: relative;

  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
  @media (max-width: 867px) {
    padding: 0;
  }
}
.inputsColumnWrapper {
  height: 100%;
  width: 50%;
  padding-right: 9px;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // padding-bottom: ;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px;
    height: 10px;
    top: 100px;
  }
  &::-webkit-scrollbar:hover {
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    // margin-top: 84px;
    margin-top: 15px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(149, 149, 149);
    border-radius: 40px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #c5cde3;
  }

  order: 1;
  @media (max-width: 867px) {
    width: 100%;
    overflow-y: auto;
    order: 2;
  }
}
.underMapOptionsContainerForAirlines {
  width: 100%;
}
.underMapOptionsWrapper {
  width: 100%;
  height: 100%;
}
.underMapOptionsContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // padding-bottom: 5px;
}

.airlinesItem {
  position: relative;
  width: 100%;
  display: flex;
  @include margin-top-bottom-for-adressfrom-inputs;
}
.airLinesInput {
  // @include ticket_icon;

  // z-index: 18;
  @include main-inputs-size-and-background-styles-etc;
  @include addressform-inputs-with-icons;
  padding-right: 8px;
  // text-indent: 38px;
  // padding: 0;
}
.flightNumberContainer {
  width: 100%;
  @include margin-top-bottom-for-adressfrom-inputs;
}
.flightNumberItem {
  position: relative;
  width: 100%;
  display: flex;
}
.flightNumberInput {
  // @include airlines_icon;

  @include main-inputs-size-and-background-styles-etc;
  @include addressform-inputs-with-icons;
}
.dateTimeBlockContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  @include margin-top-bottom-for-adressfrom-inputs;
  // @include globals_styles;
  // @import "https://bookinglane-widgets.s3.us-east-2.amazonaws.com/just-for-test/_global.scss/globals_styles";
}
.datePicker {
  width: 50%;
  margin-right: 16px;
  position: relative;
}

.datePickerOpenButton,
.datePickerOpenButtonWithRedBorder {
  // @include date_picker_icon;

  @include main-inputs-size-and-background-styles-etc;
  // @include addressform-inputs-with-icons;
  // padding-right: 0;
  height: 23px;
  font-size: 13px;
  padding-top: 10px;
  text-indent: 15px;
  background-position-x: $background-position-x-for-date-time-picker;
  background-position-y: 60%;
  cursor: pointer;
}

.datePickerOpenButtonWithRedBorder {
  border: 1px solid red;
}
// .datePickerOpenButtonIcon {
//   @include date_picker_icon;
// }
.datePickerOpenButtonText {
}
.timePicker {
  width: 50%;
  position: relative;
}
.timePickerContainer {
  display: flex;
  flex-direction: row;
  position: relative;
}
.timePickerInput,
.timePickerInputWithRedBorder {
  // @include clock_icon;
  @include main-inputs-size-and-background-styles-etc;
  @include addressform-inputs-with-icons;
  background-position-x: $background-position-x-for-date-time-picker;
}
.timePickerInputWithRedBorder {
  border: 1px solid red;
}
.toggleButtonsContainer {
  display: flex;
  flex-direction: row;

  position: absolute;
  top: 21%;
  right: 7px;

  border-radius: 3px;
}
.toggleButtonAM,
.toggleButtonPM {
  width: 30px;
  height: 21px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: $whole-app-background;
  // color: $font-color;
  transition: 0.1s;
  &:hover {
    filter: brightness(85%);
    cursor: pointer;
    transition: 0.15s;
  }
}
.toggleButtonAMSelected,
.toggleButtonPMSelected {
  width: 30px;
  height: 21px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $whole-app-background;
  color: $font-color;
  transition: 0.1s;
  filter: brightness(125%);
  // border: 1px solid green;
}

.roundTripRow,
.hourlyRow,
.meetAndGreetRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include margin-top-bottom-for-adressfrom-inputs;
}
.safetySeatRow {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // align-items: center;
  @include margin-top-bottom-for-adressfrom-inputs;
}

.roundTripWrapper,
.hourlyWrapper,
.meetAndGreetWrapper {
  width: 48%;
  // margin-right: 17px;
}
.safetySeatWrapper {
  width: 48%;
}
.hourlyCounter {
  width: 48%;
}
.luggageCounter {
  width: 100%;
}
.hourlyIconSelf {
  @include clock_icon;
  // background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 2C6.47768 2 2 6.47768 2 12C2 17.5223 6.47768 22 12 22C17.5223 22 22 17.5223 22 12C22 6.47768 17.5223 2 12 2ZM12 20.3036C7.41518 20.3036 3.69643 16.5848 3.69643 12C3.69643 7.41518 7.41518 3.69643 12 3.69643C16.5848 3.69643 20.3036 7.41518 20.3036 12C20.3036 16.5848 16.5848 20.3036 12 20.3036Z' fill='#{$main-color}'/%3E%3Cpath d='M15.899 14.8267L12.716 12.5254V7.00084C12.716 6.90262 12.6356 6.82227 12.5374 6.82227H11.4637C11.3655 6.82227 11.2852 6.90262 11.2852 7.00084V13.1482C11.2852 13.2062 11.3119 13.2598 11.3588 13.2932L15.0508 15.9852C15.1311 16.0432 15.2427 16.0254 15.3008 15.9473L15.9392 15.0767C15.9972 14.9941 15.9794 14.8825 15.899 14.8267Z' fill='#{$main-color}'/%3E%3C/svg%3E%0A")
  //   no-repeat left;
  width: 24px;
  height: 24px;
  @include counters-and-switches-icon-margins;
}
.safetySeatIconSelf {
  @include safety_seat_icon;
  width: 24px;
  height: 24px;
  @include counters-and-switches-icon-margins;
}

.meetAndGreetIconSelf {
  @include meet_and_greet_icon;
  width: 24px;
  height: 24px;
  @include counters-and-switches-icon-margins;
}

.roundTripContainer,
.safetySeatContainer,
.hourlyRowContainer,
.meetAndGreetContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // @include margin-top-bottom-for-adressfrom-inputs;
}

.roundTripIconAndNameContainer,
.safetySeatIconAndNameContainer,
.hourlyIconAndNameContainer,
.meetAndGreetIconAndNameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.preferencesTitleContainer {
  margin: 14px auto;
}

.roundTripIconAndNameTitle,
.safetySeatIconAndNameTitle,
.hourlyIconAndNameTitle,
.meetAndGreetIconAndNameTitle,
.preferencesTitle {
  @include global_second_title_styles;
}

.meetAndGreetIconAndNameTitle,
.safetySeatIconAndNameTitle,
.hourlyIconAndNameTitle {
  margin: 11px auto;
}
// .carouselContainer {
//   height: 78px;
// }

.carouselButtonsDisabled {
  width: 30px;
  height: 100%;
  background: transparent;
  color: $font-color;
  border: 1px solid $font-color;
  border-radius: $inputs-border-radius;
  border-radius: $inputs-border-radius;
  cursor: not-allowed;
  filter: brightness(70%);
  // cursor: ;
  // border-radius: ;
}
.carouselButtonsActive {
  width: 30px;
  height: 100%;
  background: $cars-type-color;
  color: $font-color;
  border: 1px solid $font-color;
  border-radius: $inputs-border-radius;
  border-radius: $inputs-border-radius;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background: $hover-color;
    color: $inner-text-on-hover;
    transition: 0.2s;
  }
}
.carItemContainer {
  width: 60px;
  height: 98%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: $cars-type-color;
  border: $inputs-border;
  border-radius: $inner-elements-border-radius;
  transition: 0.1s;
  &:hover {
    background: $hover-color;
    cursor: pointer;
    transition: 0.1s;
    .carItemTitle {
      color: $inner-text-on-hover;
    }
  }
}
.carItemContainerSelected {
  width: 60px;
  height: 98%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $hover-color;
  border: $inputs-border;
  border-radius: $inner-elements-border-radius;
  .carItemTitle {
    color: $inner-text-on-hover;
  }
}
.carItemTitleContainer {
  height: 35%;
  display: flex;
  align-items: center;
}
.carItemTitle {
  @include global_car_type_title;
  margin: 0;
}

.carImageContainer {
  height: 65%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}
.carImageStylesForBiggerTypeOfImage {
  // width: 80%;
  height: 50%;
  pointer-events: none;
  user-select: none;
}
.carImage {
  width: 95%;
  height: 75%;
  pointer-events: none;
  user-select: none;
}

// @include buttons-group;
.buttonGroupBlockContainer {
  @include buttonGroupBlockContainer;
  @include margin-top-bottom-for-adressfrom-inputs;
  @include buttons-margin-bottom;
  display: flex;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 35px;
  padding-bottom: 25px;
  @media (max-width: 867px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  // margin: 20px auto;
}

.buttonBackSelf {
  @include buttonBackSelf;
}

.buttonNextSelf {
  @include buttonNextSelf;
}

// . {
// }
// date_picker_icon
// time_picker_icon
.mapInputsContainerForHideMapsTagsPositioning {
  // margin-top: -30px;
  background: $whole-app-background;
  height: 100%;
  z-index: 10;
}
.destinationsInputContainer {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  @include margin-top-bottom-for-adressfrom-inputs;
  // flex-direction: row;
}
.addLocationIcon {
  position: absolute;
  top: 30%;
  right: 10px;
}
.deleteLocationIcon {
  position: absolute;
  top: 24%;
  right: 10px;
}

.destinationsInputWithFROMIcon,
.destinationsInputWithTOIcon,
.destinationsInputWithNumberIcon {
  position: relative;
  @include main-inputs-size-and-background-styles-etc;
  @include addressform-inputs-with-icons;
}

.numberOfDestinationPoint {
  position: absolute;
  border-radius: 50%;
  width: 21px;
  height: 19px;
  background-color: transparent;
  border: 2px solid $icons-color;
  text-align: center;
  font-weight: 700;
  font-size: 0.9rem;
  transition: 1s;
  // color: $icons-color;
  padding-top: 2px;
  margin-top: 5px;
  margin-left: 4px;
  // margin-right: -5px;
}

.dropDown {
  width: 100%;
  position: absolute;
  top: 42px;
  // border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3001;
}
// .dropDownLoadingText {
//   background-color: white;
//   font-size: 14px;
//   color: black;
//   width: 96%;
// }

.itemInsideDropDown,
.dropDownLoadingText {
  background-color: white;
  cursor: pointer;
  text-transform: none;
  justify-content: center;
  padding: 10px;
  font-size: 14px;
  color: black;
  width: 96%;
  transition: 140ms;

  &:hover {
    background-color: #d6d6d6;
    color: black;
    transition: 140ms;
  }
  &:active {
    background-color: white;
    cursor: pointer;
    text-transform: none;
    width: 340px;
    justify-content: center;
  }
}
.dropDownLoadingText {
  // text-align: center;
}
