@use "../../MAINCSSFORWHOLECOMPONENTS/SidePaddings.module" as *;

.directionsWrapper {
  //   @include side-paddings-for-whole-widget-page;
  // background: red;
  position: relative;
  width: 100%;
  height: 36.8vh;
  // @media (max-width: 1629px) {
  //   height: 43vh;
  //   // margin-bottom: 25px;
  // }
  @media (max-width: 867px) {
    height: 250px;
    // margin-bottom: 25px;
  }
  // z-index: 10;
  //   z-index: 10;
}
.directionsContainer {
  position: absolute;
  // z-index: 1;
  // z-index: 8;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  // bottom: -40px;
  // width: ;
}
