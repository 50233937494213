.loaderContainer {
  background: transparent;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}
.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid grey; /* Blue */
  border-radius: 50%;
  width: 42px;
  height: 42px;
  animation: spin 1s linear infinite;
  margin-top: 22%;
  margin-bottom: 27%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
