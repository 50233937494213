@use "../../../MAINCSSFORWHOLECOMPONENTS/AppBackgroundColor.module" as *;
@use "../../../MAINCSSFORWHOLECOMPONENTS/FontColor.module" as *;
@use "../../../MAINCSSFORWHOLECOMPONENTS/SidePaddings.module" as *;
@use "../../../MAINCSSFORWHOLECOMPONENTS/_ButtonsGroupBackAndNext.module.scss"
  as *;
@use "../../../MAINCSSFORWHOLECOMPONENTS/_CarDescription.module.scss" as *;
// car-description-model-name

// car-description-specifications

@mixin car-descriptions {
  .carContainerColumnPos {
    width: 380px;
    height: auto;
    box-sizing: border-box;
    display: flex;

    flex-direction: column;
    align-items: center;
    border: 1px solid $font-color;
    margin: 20px;
    padding: 10px 13px;
    padding-top: 16px;
    opacity: 1;
    cursor: pointer;
    @media (max-width: 595px) {
      width: 100%;
      margin: 14px auto;
      padding: 6px 18px;
    }
    // &:hover {
    //   opacity: 1;
    // }
  }

  // .carContainerColumnPosSelected {
  //   width: 380px;
  //   height: auto;
  //   box-sizing: border-box;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   border: 1px solid $font-color;
  //   margin: 20px;
  //   padding: 10px 13px;
  //   padding-top: 16px;
  //   opacity: 1;
  //   @media (max-width: 595px) {
  //     width: 100%;
  //     margin: 14px auto;
  //     padding: 6px 18px;
  //   }
  // }

  .carContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    // @media (max-width: 595px) {
    //   flex-direction: column;
    // }
    // border: 1px solid $font-color;
    // margin: 20px;
    // padding: 9px;
    // opacity: 0.5;
    // cursor: pointer;
  }

  .carImageBlock {
    width: 60%;
    margin-right: 20px;
    position: relative;
    z-index: 2;
  }
  .safetySeatNotAvailable {
    font-size: 16px;
    color: orange;
    border: 1px solid orange;
    background: grey;
    position: absolute;
    top: 46%;
    left: 30%;
    z-index: 3;
    @media (max-width: 500px) {
      top: 43%;
      left: 22%;
    }
  }
  .carImageSelf {
    position: relative;
    width: 100%;
    height: 112px;

    z-index: 2;
    cursor: zoom-in;
    @media (max-width: 500px) {
      height: 116px;
    }
  }
  .orSimiliar {
    position: absolute;
    width: 75px;
    height: 20px;
    background-color: #ac8159;
    color: $font-color;
    font-size: 13px;
    padding-left: 12px;
    // border-top-left-radius: 8px;
    padding-top: 2px;
    z-index: 4;
  }

  .carDescriptionTextBlock {
    width: 40%;
    // height: 100%;
  }
  .carDescriptionTextContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .carModel {
    @include car-description-model-name;
    margin-bottom: 15px;
  }

  .detailedDescription {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .detailedDescriptionTitleContainer {
    width: 50%;
  }
  .detailedDescriptionTitleSelf {
    @include car-description-specifications;
    color: grey;
  }
  .detailedDescriptionValueContainer {
    width: 50%;
  }

  .detailedDescriptionValueAmountSelf {
    color: $font-color;
    font-size: 15;
    font-weight: 600;
    text-align: left;
  }

  .detailedDescriptionPointedLineContainer {
    flex-grow: 1;
  }
  .detailedDescriptionPointedLineSelf {
    margin-top: 12px;
    background-color: transparent;
    margin-left: 3px;
    margin-right: 3px;
    border-bottom: 2px dotted $font-color;
  }
  .detailedDescriptionValueContainer {
  }
  .detailedDescriptionValueSelf {
    @include car-description-specifications;
  }
}

.showSafetySeatIsNotAvailable {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  width: 295px;
  z-index: 100;
  top: 40%;
}

.mainWrapperFleet {
  @include whole-app-background;
  @include side-paddings-for-whole-widget-page;
  // width: 100%;

  position: relative;
  // padding-bottom: 66px;
  // @media (max-width: 500px) {
  //   padding-top: 75px;
  // }
}
.contentContainer {
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media (max-width: 867px) {
    height: 56vh;
  }
  // padding-bottom: 70px;
}
.pageTitleContainer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.pageTitle {
  @include global-title-styles;
}
.carListContainer {
  width: 100%;
  max-height: 45vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: scroll;
  box-sizing: border-box;
  overflow-x: hidden;
  @media (max-width: 867px) {
    max-height: auto;
  }
}

// .detailedDescriptionTitleContainer {
// }
// .detailedDescriptionTitleSelf {
// }
// .detailedDescriptionPointedLineContainer {
//   flex-grow: 1;
// }
// .detailedDescriptionPointedLineSelf {
//   margin-top: 12px;
//   background-color: transparent;
//   margin-left: 3px;
//   margin-right: 3px;
//   border-bottom: 2px dotted black;
// }
// .detailedDescriptionValueContainer {
// }
// .detailedDescriptionValueSelf {
// }

@include car-descriptions;
.fleetButtonGroupForPositioning {
  width: 100%;
  height: 62px;
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 200;
  background: $whole-app-background;
  // padding-bottom: $buttons-margin-bottom;
  border-bottom-right-radius: $whole-app-border-radius;
  border-bottom-left-radius: $whole-app-border-radius;
  padding-top: 10px;

  // padding-bottom: 10px;
  // right: 10px;
  // padding: auto 10px;
}

// @include buttons-group;

.fleetButtonGroupBlockContainer {
  @include buttonGroupBlockContainer;

  padding-left: 10px;
  padding-right: 10px;
}

.buttonBackSelf {
  @include buttonBackSelf;
}

.buttonNextSelf {
  @include buttonNextSelf;
}

.carouselModal {
  width: "100%";
}
.bookinglanes_fleet_cars_carousel_dots {
}
// .modalSlider{
//   width: 100%;
//   height: 100%;
// }
