$borders-color: white;

$cars-type-color: transparent;

$hover-color: rgb(227, 202, 255);

$icons-color: white;

$back-and-next-buttons-color: pink;

$inner-text-on-hover: black;

$font-color-for-customize: white;
