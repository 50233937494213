@use "../../MAINCSSFORWHOLECOMPONENTS/_FontColor.module.scss" as *;

.termOfUseOpenButton {
  width: auto;
  height: auto;
  border: none;
  cursor: pointer;
  color: $font-color;
  background: transparent;
  font-size: 15px;
}
.termOfUseOpenedModalWindow {
  width: 600px;
  height: 100%;
  @media (max-width: 600px) {
    width: 100%;
  }
}
