@use "../../MAINCSSFORWHOLECOMPONENTS/SidePaddings.module" as *;
@use "../../MAINCSSFORWHOLECOMPONENTS/MainInputsStyles.module" as *;
@use "../../MAINCSSFORWHOLECOMPONENTS/IconsForWholeAddressFormPage" as *;
@use "../../MAINCSSFORWHOLECOMPONENTS/_AppBackgroundColor.module.scss" as *;
@use "../../MAINCSSFORWHOLECOMPONENTS/_ColorsForCustomize.module.scss" as *;

.mapWithInputsWrapper {
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
// .mapContainer {
//   // @include side-paddings-for-whole-widget-page;
//   position: relative;
//   // z-index: 10;
//   width: 100%;
//   height: 200px;

//   // z-index: 5;
// }
// .mapContainerForHideMapsTagsPositioning {
//   position: absolute;
//   z-index: 1;
//   width: 100%;
//   height: 240px;
// }
.mapInputsContainerForHideMapsTagsPositioning {
  // margin-top: -30px;
  background: $whole-app-background;
  height: 100%;
  z-index: 10;
}
.destinationsInputContainer {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  @include margin-top-bottom-for-adressfrom-inputs;
  // flex-direction: row;
}
.addLocationIcon {
  position: absolute;
  top: 30%;
  right: 10px;
}
.deleteLocationIcon {
  position: absolute;
  top: 24%;
  right: 10px;
}

.destinationsInputWithFROMIcon,
.destinationsInputWithTOIcon,
.destinationsInputWithNumberIcon {
  position: relative;
  @include main-inputs-size-and-background-styles-etc;
  @include addressform-inputs-with-icons;
}

.numberOfDestinationPoint {
  position: absolute;
  border-radius: 50%;
  width: 21px;
  height: 19px;
  background-color: transparent;
  border: 2px solid $icons-color;
  text-align: center;
  font-weight: 700;
  font-size: 0.9rem;
  transition: 1s;
  // color: $icons-color;
  padding-top: 2px;
  margin-top: 5px;
  margin-left: 4px;
  // margin-right: -5px;
}

.dropDown {
  width: 100%;
  position: absolute;
  top: 42px;
  // border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3001;
}
// .dropDownLoadingText {
//   background-color: white;
//   font-size: 14px;
//   color: black;
//   width: 96%;
// }

.itemInsideDropDown,
.dropDownLoadingText {
  background-color: white;
  cursor: pointer;
  text-transform: none;
  justify-content: center;
  padding: 10px;
  font-size: 14px;
  color: black;
  width: 96%;
  transition: 140ms;

  &:hover {
    background-color: #d6d6d6;
    color: black;
    transition: 140ms;
  }
  &:active {
    background-color: white;
    cursor: pointer;
    text-transform: none;
    width: 340px;
    justify-content: center;
  }
}
.dropDownLoadingText {
  // text-align: center;
}
