.enter {
  opacity: 0;
}
.enterActive {
  opacity: 1;
  transition: opacity 300ms;
}
.exit {
  opacity: 1;
}
.exitActive {
  opacity: 0.3;
  transition: opacity 300ms;
}
/* .leave {
  opacity: 1;
}
.leaveActive {
  opacity: 0;
  transition: opacity 500ms;
} */

/* .appear {
  opacity: 1;
} */
/* .appearActive {
  opacity: 0;
  transition: opacity 500ms;
} */
