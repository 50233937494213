@use "../MAINCSSFORWHOLECOMPONENTS/AppBackgroundColor.module" as *;
@use "../MAINCSSFORWHOLECOMPONENTS/SidePaddings.module" as *;
@use "../MAINCSSFORWHOLECOMPONENTS/FontColor.module" as *;
@use "../MAINCSSFORWHOLECOMPONENTS/_ColorsForCustomize.module.scss" as *;
@use "../MAINCSSFORWHOLECOMPONENTS/_BorderRadiuses.module.scss" as *;

.companyProfileColumn {
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
}

.companyProfileWrapper {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @include whole-app-background;
  @include side-paddings-for-whole-widget-page;
  padding-top: 10px;
  padding-bottom: 5px;

  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
  // border-bottom: 1px solid grey;
  // border-top-left-radius: $whole-app-border-radius;
  // border-top-right-radius: $whole-app-border-radius;
  border-radius: 15px;
  // cursor: all-scroll;
}
.companyProfileImageSelf {
  width: 55px;
  height: 55px;
  border-radius: 100px;
  margin-right: 10px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.companyProfileImageContainer {
  // flex-basis: 17%;
}
.companyProfileNameContainer {
  // flex-basis: 78%;
}
.companyProfileNameSelf {
  @include global-title-styles;
}
.companyProfileCloseIconContainer {
  flex-basis: 5%;
  &:hover {
    cursor: pointer;
  }
}
.companyProfileCloseIconSelf {
  // &:hover {

  // }

  width: 23px;
  height: 23px;
  position: relative;
  transition: 0.1s;
  // border-radius: $inner-elements-border-radius;

  // &:hover {
  //   background: $hover-color;
  //   transition: 0.1s;
  //   // .companyProfileCloseIconSelfCustomCreation {
  //   //   &::before {
  //   //     background: $inner-text-on-hover;
  //   //     transition: 0.1s;
  //   //   }
  //   //   &::after {
  //   //     background: $inner-text-on-hover;
  //   //     transition: 0.1s;
  //   //   }
  //   // }
  // }
}
// .companyProfileCloseIconSelfCustomCreation {
//   &::before {
//     content: "";
//     width: 17px;
//     height: 2px;
//     background: $font-color;
//     transform: rotate(225deg);
//     position: absolute;
//     bottom: 46%;
//     left: 11%;
//     transition: 0.1s;
//     // position: absolute;
//     // top: 50%;
//     // left: 50%;
//   }
//   &::after {
//     content: "";
//     width: 17px;
//     height: 2px;
//     background: $font-color;
//     transform: rotate(135deg);
//     position: absolute;
//     bottom: 46%;
//     left: 11%;
//     transition: 0.1s;
//     // position: absolute;
//     // top: 50%;
//     // left: 50%;
//   }
// }
.stepsIndicators {
  box-sizing: border-box;
  width: 77%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: grey;
  height: 40px;
  padding-left: 80px;
  padding-right: 80px;
  border-radius: 8px;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
  margin-top: 30px;
  margin-bottom: 20px;
  & > span {
    margin-right: 40px;
    &:last-child {
      margin-right: 0;
    }
    @media (max-width: 867px) {
      margin-right: 14px;
    }
  }
  @media (max-width: 867px) {
    width: 92%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  @media (max-width: 569px) {
    // width: 92%;
    font-size: 13px;
    padding-left: 15px;
    padding-right: 15px;
  }

  // border-bottom: 1px solid grey;
  // border-top: 1px solid grey;
}
