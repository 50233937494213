.showModal {
  // width: 100%;
  // height: 100%;
  display: block;
  width: 100%;
  // z-index: 99999999999999999999999999999999999999999;
}
.hideModal {
  display: none;
  width: 100%;
}

.modal {
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  // margin-top: 20px;
  // margin-bottom: 20px;

  //   opacity: 0;
  //   transition: all 0.3s ease-in-out;
  //   pointer-events: none;
}

// .modal.enter-done {
//   opacity: 1;
//   pointer-events: visible;
// }

// .modal.exit {
//   opacity: 0;
// }

.modalContent {
  width: auto;
  height: auto;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  // position: fixed;
  // left: 0;
  // top: 0;
  // right: 0;
  // bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  // width: auto;
  // height: auto;
  // position: relative;
  // transition: all 0.3s ease-in-out;
  // transform: translateY(-200px);
}

// .modal.enter-done .modalContent {
//   transform: translateY(0);
// }

// .modal.exit .modalContent {
//   transform: translateY(-200px);
// }

.modalBody {
  width: auto;
  height: auto;
  background-color: #fff;
  border-radius: 4.5px;
  // padding: 10px;
  //   border-top: 1px solid #eee;
  //   border-bottom: 1px solid #eee;
  // overflow-y: scroll;
}
