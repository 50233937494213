.showModal {
  // width: 100%;
  // height: 100%;
  display: block;
  width: 100%;
}
.hideModal {
  display: none;
  width: 100%;
}

.modal {
  width: 100%;
  // height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-top: 20px;
  // margin-bottom: 20px;

  //   opacity: 0;
  //   transition: all 0.3s ease-in-out;
  //   pointer-events: none;
}

// .modal.enter-done {
//   opacity: 1;
//   pointer-events: visible;
// }

// .modal.exit {
//   opacity: 0;
// }

.modalContent {
  width: auto;
  height: 80%;

  // position: fixed;
  // left: 0;
  // top: 0;
  // right: 0;
  // bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  // width: auto;
  // height: auto;
  // position: relative;
  // transition: all 0.3s ease-in-out;
  // transform: translateY(-200px);
}

// .modal.enter-done .modalContent {
//   transform: translateY(0);
// }

// .modal.exit .modalContent {
//   transform: translateY(-200px);
// }

.modalHeader,
.modalFooter {
  // bottom: 10%;
  // padding: 10px;
}

.modalFooter {
  width: 100%;
  background-color: #fff;
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
}

.modalTitle {
  margin: 0;
}

.modalBody {
  background-color: #fff;
  padding: 10px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  overflow-y: scroll;
}
.modalOkButton {
  // position: fixed;
  width: 44px;
  height: 34px;
  margin: 10px;
  border: none;
  background: rgb(189, 189, 189);
  color: black;
  border-radius: 5px;
  &:hover {
    background: rgb(90, 90, 90);
    color: white;
    cursor: pointer;
  }
  // bottom: 0;
}
