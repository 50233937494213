@use "../../../MAINCSSFORWHOLECOMPONENTS/AppBackgroundColor.module" as *;
@use "../FleetForm/FleetForm.module.scss" as *;
@use "../../../MAINCSSFORWHOLECOMPONENTS/FontColor.module" as *;
@use "../../../MAINCSSFORWHOLECOMPONENTS/SidePaddings.module" as *;
@use "../../../MAINCSSFORWHOLECOMPONENTS/_ButtonsGroupBackAndNext.module.scss"
  as *;
@use "../../../MAINCSSFORWHOLECOMPONENTS/_MainInputsStyles.module.scss" as *;

@include car-descriptions;
.carContainerColumnPos {
  width: 100%;
  padding: 0;
  margin: 0;
  opacity: 1;
}
.carContainer {
  border-bottom: 1px solid grey;

  padding-bottom: 22px;
  margin-bottom: 20px;
}
.carImageBlock {
  width: 50%;
  margin-left: 20px;
}
.carImageSelf {
  width: 100%;
  height: 170px;
  @media (max-width: 1022px) {
    height: 126px;
  }
  @media (max-width: 500px) {
    height: 86px;
  }
}
.carDescriptionTextBlock {
  padding-top: 20px;
  // @media (max-width: 867px) {
  //   text-align: center;
  // }
}
.detailedDescription {
  width: 80%;
}
.detailedDescriptionTotal {
  width: 100%;
  display: flex;
  justify-content: center;
}
.detailedDescriptionValueContainerTotal {
  // width: 55%;
}
.detailedDescriptionWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
  // padding-left: 150px;
}
.detailedDescriptionWrapperTotal {
  height: 65px;
  border-top: 1px solid black;
  margin-top: 0px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}
.previewWrapper {
  @include whole-app-background;
  @include side-paddings-for-whole-widget-page;

  width: auto;
  height: auto;
  // overflow-y: auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 867px) {
    height: auto;
  }
  // @media (max-width: 500px) {
  //   padding-top: 75px;
  // }
}
.reservationDetailsWrapper {
  width: 49%;
  height: 100%;
  // margin-right: 20px;
  order: 1;
  @media (max-width: 867px) {
    width: 100%;
    height: auto;
    order: 2;
    margin-right: 0px;
  }
}
.reservationDetailsContainer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  // border-radius: 15px;
  box-shadow: 0px 8px 20px 4px rgba(0, 0, 0, 0.08);
  padding-top: 20px;
  @media (max-width: 867px) {
    overflow-y: hidden;
  }
}
// .carContainer {
//   // @include margin-top-bottom-for-adressfrom-inputs;
//   // margin: 0 auto;
//   // width: auto;
//   // height: 100%;
//   opacity: 1;
// }
// .carImageBlock {
//   height: 100%;
// }

.previewTitleContainer {
  margin-top: 15px;
  margin-bottom: 35px;
  text-align: center;
  @media (max-width: 867px) {
    margin-bottom: 17px;
  }
}
.previewTitleSelf {
  @include global-title-styles;
}
.previewRowPos {
  height: 41vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 867px) {
    height: 47.6vh;
    flex-direction: column;
    overflow-y: auto;
  }
}
.directionsContainer {
  // padding-bottom: -100px;
  // height: 200px;
  width: 49%;
  height: 100%;
  // width: 100%;
  // height: 200px;
  color: grey;
  order: 2;
  z-index: 10;

  @media (max-width: 867px) {
    width: 100%;
    order: 1;
  }
  // padding-bottom: -40px;
}
.directionsSelf {
  // padding: 15px;
  padding: 20px;

  border-radius: 10px;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);

  @media (max-width: 867px) {
    padding: 10px;
    border: 1px solid white;
    margin-bottom: 25px;
  }
  // @media (max-width: 541px) {
  //   padding: 0;
  // }
}
// .directionsContainerForPositioning {
//   position: absolute;
//   width: 100%;
//   height: 200px;
//   bottom: -20px;
// }
.reservationDetailsItem {
  margin-bottom: 16px;
}
.reservationDetailsItemContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.reservationDetailsItemTitleContainer {
  width: 30%;
  margin-right: 20px;
}
.reservationDetailsItemTitleSelf {
  white-space: normal;
  color: grey;
}
.reservationDetailsItemPointedLineContainer {
  flex-grow: 1;
}
.reservationDetailsItemPointedLineSelf {
  margin-top: 12px;
  background-color: transparent;
  margin-left: 3px;
  margin-right: 3px;
  border-bottom: 2px dotted $font-color;
}

.reservationDetailsItemValueContainer {
  position: relative;
  display: inline;
  text-align: left;
  width: 70%;
  // text-align: right;
}
.reservationDetailsItemValueSelf {
  display: inline;
  // fontSize: "16px",

  width: 100%;
  color: grey;
}

.notesTextFieldContainer {
  width: 100%;
  // @include margin-top-bottom-for-adressfrom-inputs;
  margin-top: 30px;
  padding-top: 10px;
  border-top: 1px solid grey;
  // margin-bottom: 15px;
}
.notesTextFieldSelf {
  width: 100%;
  // display: block;
  height: 72px;
  color: $font-color;
  font-family: "Vazir", sans-serif;
  font-size: 15px;
  border: none;
  outline: none;
  resize: none;
  padding: 0;
  background: transparent;
  border-bottom: 1px solid $font-color;
  &::-webkit-input-placeholder {
    font-size: 15px;
    color: $font-color;
    font-family: "Vazir", sans-serif;
  }
  &:-moz-placeholder {
    /* Upto Firefox 18, Deprecated in Firefox 19  */
    font-size: 15px;
    color: $font-color;
    font-family: "Vazir", sans-serif;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 15px;
    color: $font-color;
    font-family: "Vazir", sans-serif;
  }
  &:-ms-input-placeholder {
    font-size: 15px;
    color: $font-color;
    font-family: "Vazir", sans-serif;
  }
}

// @include buttons-group;
.buttonGroupBlockContainer {
  @include buttonGroupBlockContainer;
  @include margin-top-bottom-for-adressfrom-inputs;
  @include buttons-margin-bottom;
}

.buttonBackSelf {
  @include buttonBackSelf;
}

.buttonNextSelf {
  @include buttonNextSelf;
}

.gratuityValueInput {
  box-sizing: border-box;
  width: 170px;
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;
  border-radius: 2px;
  background: transparent;
  box-shadow: 0px 0px 1.09615px rgba(0, 0, 0, 0.25);
}
.customDollarAndInputContainer {
  width: 34px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropDownIconContainer {
  width: 20px;
  height: 20px;
  display: flex;
  // justify-content: center;
  align-items: center;
  padding-left: 4px;
  margin-right: -10px;
  box-sizing: border-box;
  border-radius: 2px;
  background: #ffffff;

  box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.dropDownIcon {
  // transform: rotate(90deg);
  color: #4e4e4e;
  transform: scale(1, 0.5) rotate(90deg);
}
.gratuityValue {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  // margin-left: 50px
  padding-top: 2px;
  color: #000000;
}
.customTipsModalContainer {
  width: 224px;
  height: auto;
  // position: absolute;
  // top: 60%;
  // left: 37%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 9px;
  z-index: 99999;
  border-radius: 2px;
  background: #1a1a1a;
  border: 1px solid #282828;
  border-radius: 3.45349px;
}
.customDollarAndInputContainer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8.1px;
}
.okAndXContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropDownContainer {
  position: absolute;
  box-sizing: border-box;
  width: 90px;
  height: auto;
  left: -30px;
  top: 27px;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  background: #ffffff;
  color: black;
  z-index: 90000;

  // & > span {
  //   box-sizing: border-box;
  //   width: 100%;
  //   padding-top: 5px;
  //   padding-bottom: 5px;
  //   padding-left: 8px;
  //   padding-right: 8px;
  //   cursor: pointer;
  //   &:hover {
  //     background: #bababa;
  //   }
  // }
}
.dropDownItem {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  &:hover {
    background: #bababa;
  }
}
.selectedIcon {
  color: #ffffff;
  transform: scale(1.2, 0.7) rotate(90deg);
}
